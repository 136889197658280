import React, { useContext, useEffect, useMemo, useState } from 'react'
import Form, { InputNumber } from '#Form'
import Dialog from '#Dialog'

import APICtx from '#api'

const EditForm = ({ _id, quantity }) => {
	const api = useContext(APICtx)
	const [ data, setData ] = useState({})

	useEffect(() => {
		setData({ quantity })
	}, [ _id ])

	const formDef = {
		_id,
		callSet: "ops/job/timer/finish",
		data, setData,
	}

	return (
		<Form {...formDef}>
			<InputNumber decimals={0} label="Quantità pezzi completati" name="quantity" />
		</Form>
	)
}

const DoneConfirm = ({ _id, phase, label, quantity, open, handleClose, handleRefresh}) => {
	const triggerClose = () => {
		handleClose()
		handleRefresh?.()
		// handleRowRefresh?.()
	}

	const title = useMemo(() =>
		'Conferma completamento fase' + (label ? ' - '+label : ''),
		[ label ],
	)

	return (
		<Dialog
			handleClose={triggerClose}
			open={open}
			title={title}
			width="md"
		>
			<EditForm _id={_id} phase={phase} quantity={quantity} />
		</Dialog>
	)
}
export default DoneConfirm
