import React, { useContext } from 'react';
import QRCode from "react-qr-code";
import {
	Box,
	Dialog as DialogMui,
	DialogTitle,
	Typography,
} from '@mui/material';
import {  } from '@mui/material';

import { TplConfCtx, TplConfig } from './Template'

const Dialog = ({ title, open, handleClose, children, width }) => {
	const fullWidth = Boolean(width)
	const maxWidth = width || 'sm'
	return (
		<DialogMui onClose={handleClose} open={Boolean(open)} fullWidth={fullWidth} maxWidth={maxWidth}>
			<TplConfig backTo={handleClose}>
				{ title && <DialogTitle>{title}</DialogTitle> }
				{children}
			</TplConfig>
		</DialogMui>
	)
}
export default Dialog

export const QrDetails = ({ url, label, level, handleClose }) => {
	const tplConf = useContext(TplConfCtx)

	return (
		<Dialog
			handleClose={handleClose}
			open={Boolean(url)}
			title={label}
			width="md"
		>
			{ Boolean(tplConf.logo) && (
				<Box
					component="img"
					src={tplConf.logo}
					sx={{
						display: 'none',
						width: 'auto',
						height: 'auto',
						margin: '0 auto',
						textAlign: 'center',
						mb: 2,
						'@media print': {
							display: 'block',
						}
					}}
				/>
			)}
			{ url && (
				<QRCode
					size={512}
					value={url}
					style={{ margin: "0 auto" }}
					level={level || 'L'}
				/>
			)}
			<Typography sx={{ textAlign:'center', m:2, '@media print': { display:'none' } }} variant='h6'>
				<a target='_blank' rel="noreferrer" href={url}>Apri il link</a>
			</Typography>
		</Dialog>
	)
}
